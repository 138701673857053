.footer_section.MuiToolbar-root {
  gap: 1.2em;

  * {
    text-wrap: nowrap;
    font-family: var(--font-family2);
  }

  .MuiTypography-root {
    text-transform: capitalize;

    &.MuiTypography-body2.link {
      cursor: pointer;

      &:hover {
        opacity: .8;
      }
    }
  }

  .MuiIconButton-root {
    color: var(--dark-color);
  }
}

.footer_inner_section {
  display: inline-flex;
  align-items: center;
  justify-content: space-evenly;
  font-size: 12px;
  width: 100%;
  box-sizing: border-box;

  .language_country_section {
    width: 20%;
  }

  .membership {
    width: 25%;

    a {
      padding: 0 3px;
    }
  }

  .copyright {
    width: 45%;
    text-align: center;
    margin-left: 20px;
  }

  .social_icon {
    width: 10%;
    text-align: end;
  }
}



@media (max-width:1230px) {
  .footer_inner_section {
    flex-wrap: wrap;
    // justify-content: space-between;
  }

  .language_country_section {
    width: 30% !important;
    padding: 10px;
  }

  .membership {
    display: flex;
    justify-content: center;
    padding: 10px;
    width: 40% !important;

    a {
      padding: 0 10px !important;
    }
  }

  .social_icon {
    padding: 10px;
  }

  .copyright {
    width: 100% !important;
    padding: 10px;
    order: 4;
    text-align: center;
  }
}

@media (max-width:800px) {
  .language_country_section {
    order: 1;
    width: 45% !important;
  }

  .membership {
    order: 3;
    display: flex !important;
    justify-content: space-between !important;
    width: 100% !important;
  }

  .social_icon {
    order: 2;
    width: 45% !important;
  }

  .copyright {
    order: 4;
    margin-left: 0 !important;
  }

  .footer_section.MuiToolbar-root *{
    text-wrap: wrap!important;
  }
}

@media (max-width:550px){
  .footer_inner_section{
    font-size: 11px !important;
  }
}

@media (max-width:432px) {
  .language_country_section,
  .membership,
  .social_icon,
  .copyright{
    width: 100% !important;
    display: flex;
    justify-content: center;
  }

  .footer_section.MuiToolbar-root *{
    text-wrap: wrap!important;
  }
}