.details_section {
  width: 100%;

  .MuiTypography-root {
    font-family: var(--font-family2) !important;
  }

  .details_cell_1 {
    .image_area {
      width: 100%;
      object-fit: cover;
      height: 100%;
    }
  }

  .details_cell_2 {
    .subHeading {
      text-transform: uppercase;
    }

    .subHeading_text {
      text-transform: uppercase;
      color: var(--softPink-color) !important;
    }

    .size_section {
      display: flex;
      width: 100%;
      justify-content: space-between;

      >.MuiTypography-body1 {
        width: max-content;

        &.link {
          text-decoration: underline;
          cursor: pointer;
        }

        &:nth-child(1) {
          text-transform: uppercase;
          font-weight: bold;
        }
      }
    }

    .size_cell {
      border: 1px solid #ddd;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 60px;
      flex-direction: column;
      cursor: pointer;

      &:hover,
      &:focus {
        border-color: var(--dark-color);
        background-color: var(--merino-color);
      }
    }
  }




  .addToCart_btn {
    height: 45px;
    // margin: 16px 0;
    // color: var(--softPink-color);
    // background-color: var(--merino-color);
    color: var(--light-color);
    background-color: var(--dark-color);

    &:hover,
    &:focus {
      // background-color: var(--merino-color);
      // border-color: var(--softPink-color);
      // color: var(--softPink-color);
      background-color: var(--dark-color);
      border-color: var(--dark-color);
      color: var(--light-color);
    }
  }

  .divider-light {
    margin: 16px 0;
  }
}

@media (max-width:600px) {
  .accordion {
    padding: 10px 5px !important;
  }

  .freshwater_section {
    padding-left: 0px !important;
  }

  .freshwaterimg {
    padding-right: 0 !important;
  }

  .details_cell_1 {
    // padding: 12px 6px !important; 
  }

  .matels_diamond_section{
    padding-right: 0 !important;
  }

  .General_section{
    padding-left:  0 !important;
  }

  .MuiListItemText-root {
    width: 80%;
  }
}

@media (max-width:500px) {
  .MuiListItemText-root {
    width: 75%;
  }
}

@media (max-width:400px) {
  .MuiListItemText-root {
    width: 65%;
  }
}