.header_section {
  
  .MuiTypography-root {
    font-family: var(--font-family2);
  }
  .MuiToolbar-root.subHeader {
    z-index: 999;
    width:99vw;
    position: fixed;
    top:0;
    background-color: var(--dark-color);
    min-height: 40px;
    height: 40px;
    padding: 0 5px;
    background-color: #fbcdd5;
    color: black;
    a {
      text-decoration: underline;
    }
    .MuiIconButton-root {
      height: 100%;
      font-size: 30px;
      color: rgba(255, 255, 255, 0.7);
      margin: 0;
      .MuiSvgIcon-root {
        font-size: 2rem;
      }
    }
  
    .MuiTypography-root.MuiTypography-body2.navigation_btn {
      cursor: pointer;
      &.MuiTypography-root.MuiTypography-body2.location_btn {
        display: flex;
        align-items: center;
        gap: 4px;
      }
    }
  }


  //main header
  .MuiToolbar-root.main_header {
    width:99vw;
    position: fixed;
    top:39px;
    z-index: 999;
    background-color: var(--light-color);
    color: var(--dark-color);
    padding: 0 8px;
    gap: 20px;
    box-shadow: 0px 9px 15px 5px rgba(117,117,117,0.2);
    img {
      width: 250px;
      margin-right: 4rem;
      cursor: pointer;
    }
    .mainHeader_nav {
      text-transform: uppercase;
      cursor: pointer;
      color: var(--dark-color);
      font-weight: bold;

    }
    .icon_btn {
      // margin-right: 20px;
      color: var(--dark-color);
      &:hover {
        opacity: 0.6;
      }
    }
    .active-menu {
      color: var(--dark-color);
      // display: none;
      &:hover {
        opacity: 0.6;
      }
    }
    .responsive-drawer {
      display: none;
    }
  }

  .subHeader.hidden {
    display: none; // This will hide the subheader
  }

  .main_header {
    transition: top 0.3s; // Smooth transition for main header position
  }
}



//  responsive sub header
.MuiToolbar-root.responsive_subHeader {
  width: 100%;
  z-index: 999;
  position: fixed;
  top:0;
  background-color: var(--dark-color);
  min-height: 40px;
  height: 40px;
  // padding: 0 8px;
  background-color: #fbcdd5;
  color: black;
  display: none;
  a {
    text-decoration: underline;
  }
  .MuiIconButton-root {
    height: 100%;
    // padding: 0 8px;
    font-size: 30px;
    color: rgba(255, 255, 255, 0.7);
    margin: 0;
    .MuiSvgIcon-root {
      font-size: 2rem;
    }
    transition: top 0.3s ease-in-out;
  }

  .MuiTypography-root.MuiTypography-body2.navigation_btn {
    cursor: pointer;
    &.MuiTypography-root.MuiTypography-body2.location_btn {
      display: flex;
      align-items: center;
      gap: 4px;
    }
  }
  .subHeader.hidden {
    display: none; // This will hide the subheader
  }

  .main_header {
    transition: top 0.3s; // Smooth transition for main header position
  }
}

@media (max-width: 1100px) {
  .header_section .MuiToolbar-root.main_header {
    gap: 8px;
  }
  .header_section .MuiToolbar-root.main_header img {
    margin-right: 1rem;
    width: 150px;
  }

  
}

//v-my-code
@media (max-width: 768px) {
  .header_section .MuiToolbar-root.main_header {
    gap: 13px;
  }
  .main_header .logo_section {
    width: 180px !important;
    height: 60px;
  }
  
  .mainHeader_nav {
    display: none;
  }
  .responsive-drawer {
    display: block !important;
  }
  .header_section {
    display: none;
  }
  //sub-header
  .MuiToolbar-root.subHeader {
    display: none;
  }
  .MuiToolbar-root.responsive_subHeader {
    display: block;
  }
  .header_section .MuiToolbar-root.main_header img{
    width:""
  }
}

@media (max-width: 400px) {
  .header_section .MuiToolbar-root.main_header {
    gap: 10px;
  }

  .main_header .logo_section {
    width: 160px !important;
    height: 60px;
  }
  .header_section .MuiToolbar-root.subHeader{
    font-size: 5px;
  }
  
  .mainHeader_nav {
    display: none;
  }
  .responsive-drawer {
    display: block !important;
  }
  .header_section {
    display: none;
  }
  //sub-header
  .MuiToolbar-root.subHeader {
    display: none;
  }
  .MuiToolbar-root.responsive_subHeader {
    display: block;
  }
}