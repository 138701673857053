
.categories_drawer {
  display: flex;
  height: auto;
  background-color: black;
  // position: relative;
  width: 100%;
  overflow-x: auto;
  
  &::-webkit-scrollbar {
    height: 3px;
    width: 10px;
  }
  
  &::-webkit-scrollbar-track {
    background: rgb(70, 68, 68);
  }
  
  &::-webkit-scrollbar-thumb {
    background-color: #de98a4;
    border-radius: 30px;
    &:hover {
      background: #da7283;
    }
  }


  
}

.scroll-button {
  background-color: rgba(255, 255, 255, 0.8); 
  border: none;
  border-radius: 50%; 
  width: 30px;
  height: 50px; 
  cursor: pointer;
  position: sticky; 
  z-index: 10; 
  width: 100px;
  // height:100px
}
.scroll-button.left {
  left: 20px; /* Adjust positioning */
  top: 50%; /* Center vertically */
  transform: translateY(-50%); /* Center vertically */
}

.scroll-button.right {
  right: 20px; /* Adjust positioning */
  top: 50%; /* Center vertically */
  transform: translateY(-50%); /* Center vertically */
}




.categories_drawer_img {
  flex: 0 0 auto;
  width: 200px;
  height: 200px;
  margin: 5px;
  border-radius: 8px;
  overflow: hidden !important ;
  position: relative;
  cursor: pointer;

  .innerImg {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
    &:hover {
      transform: scale(1.2);
    }
  }

  .nameOverly {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    color: white;
    padding: 8px;
    text-align: center;
    font-size: 24px;
  }
}
