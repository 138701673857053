.splash-screen {
  
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  z-index: 9999; 
 

  background-image: linear-gradient(
  345deg,
  hsl(0deg 0% 41%) 0%,
  hsl(344deg 0% 21%) 35%,
  hsl(0deg 0% 0%) 100%
  );

  display: flex;
  flex-direction: column;
  gap: 60px;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  overflow: hidden;
  animation: fadeOut 3s ease-in-out forwards;
  animation-delay: 3s;
}

.image_container {
  background-color: white;
  height: 120px;
  width: 120px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(60deg, #e3b1d6, #ebdce4,#f4d5e2);
  box-shadow: -2px -2px 90px 20px rgba(0,0,0,0.61);
  
}

.image_container img {
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
  object-position: center;
}

.splash-screen .logo {
  font-size: 3rem;
  color: #ecf0f1;
  margin-top: 20px;
  animation: bounce 2s infinite;
}

.splash-screen .tagline {
  font-size: 1.5rem;
  color: #ecf0f1;
}

@keyframes fadeOut {
  0% { opacity: 1; }
  100% { opacity: 0; }
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-60px);
  }
  60% {
    transform: translateY(-30px);
  }
}




////flash
.dot-flashing {
  position: relative;
  width: 13px;
  height: 13px;
  border-radius: 10px;
  background-color: #9880ff;
  color: #9880ff;
  animation: dot-flashing 1s infinite linear alternate;
  animation-delay: 0.5s;
}
.dot-flashing::before, .dot-flashing::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}
.dot-flashing::before {
  left: -40px;
  width: 13px;
  height: 13px;
  border-radius: 10px;
  background-color: #9880ff;
  color: #9880ff;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 0s;
}
.dot-flashing::after {
  left: 40px;
  width: 13px;
  height: 13px;
  border-radius: 10px;
  background-color: #efb3efe7;
  color: #e8e6f1;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dot-flashing {
  0% {
    background-color: #e8e6f1;
  }
  50%, 100% {
    background-color: #efb3efe7;
  }
}





