.new_arrival {
  padding: 2rem;
  // border: 1px solid var(--dark-color);

  .heading {
    text-transform: uppercase;
    font-weight: bold;
    color: var(--dark-color);
    font-family: var(--font-family3);
  }
  .arrival_button {
    height: 45px;
    margin-bottom: 2rem; 
    color: var(--dark-color);
    color: var(--softPink-color);
    background-color: var(--merino-color);    
    &:hover, &:focus{
      background-color: var(--merino-color);
      border-color: var(--softPink-color);
      color: var(--softPink-color);
    }
  }
}
.cards_new_arrival {
  box-sizing: border-box;
  padding: 0 5px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.react-multiple-carousel__arrow{
  background-color: var(--light-color) !important;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 4px !important;
  &::before{
    color: var(--dark-color) !important;
  }
  &:hover {
    background-color: #ededed !important;
  }
z-index: 800 !important;
}



// .react-multiple-carousel__arrow--right .react-multiple-carousel__arrow--left{
//   z-index: 800 !important;
// }