.productsList_section {
  padding: 40px 60px;

  .productList_heaing {
    .MuiTypography-root {
      font-family: var(--font-family3) !important;
    }

    .title_heading {
      font-weight: bold;
      text-transform: uppercase;
    }

    .filter_btn {
      height: 45px;
      margin: 16px 0;
      color: var(--softPink-color);
      background-color: var(--merino-color);

      &:hover,
      &:focus {
        background-color: var(--merino-color);
        border-color: var(--softPink-color);
        color: var(--softPink-color);
      }
    }
  }

  .productList_card {
    height: max-content;
    cursor: pointer;

    .card_img-section {
      position: relative;
      width: 100%;
      img{
        width: 100%;
      }

      .heart_icon {
        position: absolute;
        top: 10px;
        right: 10px;
        background-color: transparent;
        z-index: 9;

        &:hover {
          background-color: transparent;
        }
      }
    }
  }
}

@media (max-width:900px) {
  .Skeleton_height {
    height: 350px !important;
  }
}

@media (max-width:600px) {
  .productsList_section{
    // margin-top: 90px;
    padding: 20px 20px;
  }
  .productsList_section .productList_heaing .filter_btn {
    height: 42px;
    font-size: 14px;

  }
}

@media (max-width:768px) {
  .productsList_section{
    // margin-top: 90px;
    padding: 20px 40px;
  }
  .productsList_section .productList_heaing .filter_btn {
    height: 42px;
    font-size: 14px;

  }
}


@media (max-width:450px) {
  .productsList_section{
    padding: 20px 20px;
  }
  .productsList_section .productList_heaing .filter_btn {
    height: 40px;
    font-size: 12px;
  }
}

@media (max-width:360px) {
  .productsList_section .productList_card .card_img-section .heart_icon {
    top: 2px;
    right: 2px;
  }
}