:root {
  --font-family2: brandon-grotesque, sans-serif;
  --font-family3: monospace;
  --dark-color: rgb(0, 0, 0);
  --light-color: white;
  --merino-color: #faf7f0;
  --bigCords3-bg: #f9f7f2;
  --LightGrayishBrown-color:#ece9e0;
  --softPink-color: #c58b93;
  --Price-color:#0f743b;
}


//scrollbar
::-webkit-scrollbar {
  height: 3px; /* For horizontal scrollbar */
  width: 5px; /* For vertical scrollbar */
}

::-webkit-scrollbar-track {
  background: rgb(255, 255, 255); /* Track background */
}

::-webkit-scrollbar-thumb {
  background-color: #FBCDD5; /* Thumb color */
  border-radius: 30px; /* Rounded edges */
}

::-webkit-scrollbar-thumb:hover {
  background: #948a8b; /* Thumb color on hover */
}






@font-face {
  font-family: brandon-grotesque;
  src: url("./assets/fonts/HvDTrial_Brandon_Grotesque_regular-BF64a625c9311e1.otf")
    format("opentype");
}

body {
  // font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  //   "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  //   sans-serif;
  font-family: var(--font-family2);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; 
  scroll-behavior: smooth;
  margin: 0;
  padding: 0;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.underline-right,
.underline-right-dark {
  z-index: 10;
  position: relative;
  &:after {
    position: absolute;
    z-index: -1;
    content: "";
    width: 100%;
    height: 1px;
    left: 0;
    bottom: 0;
    transform: scaleX(0);
    transition: transform 300ms ease-out 0s;
    background-color: var(--light-color);
    transform-origin: bottom right;
  }
  &:focus:after,
  &:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }

  &.underline-right-dark:after {
    background-color: var(--dark-color);
  }
}
