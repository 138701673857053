.storeAndServices {
 
  .seeAllStore {
    display: flex;
    align-items: center;
    gap: 8px;
    line-height: normal;
  }

  .MuiTypography-body2 {
    font-size: 0.78rem;
  }
  .signInHeading {
    font-weight: bold;
  }
  .signInText {
    font-family: var(--font-family2);
    font-size: large;
    a {
      text-decoration: underline;
      text-underline-position: under;
      cursor: pointer;
    }
  }
  .signInBtn {
    background-color: var(--dark-color);
    color: var(--light-color);
    height: 45px;
    font-weight: bold;
    letter-spacing: 0.1rem;
    font-size: 1.2rem;
    &:hover {
      background-color: var(--dark-color);
      opacity: 0.8;
    }
  }

  .profileBtns {
    background-color: var(--dark-color);
    color: var(--light-color);
    height: 35px;
    margin: "5px 0px";
    font-weight: bold;
    letter-spacing: 0.1rem;
    font-size: 0.7rem;
    &:hover {
      background-color: var(--dark-color);
      opacity: 0.8;
    }
  }


}

.mainHeaderDrawer {
  .MuiTypography-body1.ListSubHeading {
    font-weight: bold;
  }
  .mainListItem, .ListSubHeading {
    cursor: pointer;
    padding: 0;
    width: max-content;
    margin-bottom: 4px;
    &:hover {
      opacity: 0.6;
    }
  }
}

.storeAndServices,
.mainHeaderDrawer,
.searchDrawer{
  &.MuiDrawer-root,
  .MuiBackdrop-root {
    top: 105px;
  }
}
.MuiTypography-root {
  font-family: var(--font-family2) !important;
}
.topSearch-list {
  padding: 0 !important;
  font-family: var(--font-family2) !important;
  width: max-content !important;
  &:hover {
    opacity: 0.8;
  }
}
.cartDrawer {
  .MuiButtonBase-root.cart-button {
    color: var(--dark-color);
    // border-radius: 0;
    border: 1px solid var(--dark-color);
    &:hover {
      border: 1px solid var(--dark-color);
    }
  }
}
.searchDrawer {
  .serch_cell {
    position: relative;
    height: 70px;
    &::after {
      content: "";
      height: 16px;
      position: absolute;
      left: 0px;
      right: 0px;
      bottom: -16px;
      background: linear-gradient(
        rgb(255, 255, 255) 0%,
        rgba(255, 255, 255, 0) 100%
      );
      z-index: 1;
    }
  }
  .productList {
    padding-top: 10px;
    overflow-y: auto;
    scroll-behavior: smooth;
    position: relative;
    height: 310px;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}
.divider {
  border-color: var(--dark-color) !important;
  margin: 24px 0 !important;
}


.outlined {
  // border-color: rgba(0, 0, 0, 0.23); /* Default outline color */
  border-width: 0px 0px 1px !important;
  border-radius: 0px !important;
}

.outlined-focused {
  // border-color: #1976d2; /* Change to /desired color when focused */
  border-width: 1px;
}

@media (max-width:600px) {
  .MuiTypography-h5 {
    font-size: 1.1rem !important;
  }  
  .signInText{
    display: block;
  }
}